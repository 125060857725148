import { Security } from '@okta/okta-react'
import {
  LoginCallback,
  NotAuthorized,
  UserNotFound,
  PageNotFound,
  LogoutRedirect,
  VpnOff
} from 'components/ui'
import { OktaProvider } from 'contexts/oktaContext'
import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import NewEnvironment from 'templates/NewEnvironment'

import { oktaAuth } from './oktaConfig'

export default function MainRoutes() {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    const navigate = useNavigate()
    navigate(originalUri || '/', { replace: true })
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <OktaProvider>
        <Routes>
          <Route path="/" element={<NewEnvironment />} />

          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="/not-allowed" element={<NotAuthorized />} />
          <Route path="/login-not-found" element={<UserNotFound />} />
          <Route path="/redirect-logout" element={<LogoutRedirect />} />
          <Route path="/vpn-off" element={<VpnOff />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </OktaProvider>
    </Security>
  )
}
