import { Flex, Image, Text, Box } from '@chakra-ui/react'
import { useAlert } from 'hooks/useAlert'
import React from 'react'

const NewEnvironment = () => {
  const { alert } = useAlert()

  const handleCopyToClipboard = () => {
    const url = 'https://mbpbackoffice.pluxee.com.br/'
    navigator.clipboard.writeText(url).then(() => {
      alert({
        id: 'linkCopiedAlert',
        title: 'O link foi copiado para a área de transferência!',
        status: 'success'
      })
    })
  }

  return (
    <Flex
      fontFamily="TTTravels"
      bg="#FAF8FF"
      w="100%"
      h="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src="/img/plx-icon-not-found.png" alt="Not authorized icon" />

      <Text
        color="#221C46"
        letterSpacing="-1.2px"
        fontWeight="700"
        fontSize="24px"
        lineHeight="31.2px"
        pt="66px"
      >
        Ops...
      </Text>

      <Text
        color="#221C46"
        letterSpacing="-1.2px"
        fontWeight="700"
        fontSize="24px"
        lineHeight="31.2px"
      >
        Você está acessando um ambiente antigo
      </Text>

      <Box pt="16px" maxW="402px" textAlign="center">
        <Text
          color="#221C46"
          fontSize="20px"
          fontWeight="500"
          lineHeight="25px"
          letterSpacing="-1px"
        >
          <Text
            as="span"
            color="#1B51DC"
            textDecoration="underline"
            cursor="pointer"
            _hover={{
              color: '#526CF8'
            }}
            _active={{
              color: '#0F266D'
            }}
            onClick={() =>
              window.open('https://mbpbackoffice.pluxee.com.br/', '_blank')
            }
          >
            Clique aqui
          </Text>{' '}
          para ser redirecionado ao novo ambiente, ou copie o link abaixo:
        </Text>

        <Flex
          justifyContent="center"
          alignItems="center"
          pt="16px"
          gap="8px"
          onClick={handleCopyToClipboard}
          cursor="pointer"
          _hover={{
            '> svg': {
              fill: '#526CF8'
            },
            '> span': {
              color: '#526CF8'
            }
          }}
          _active={{
            '> svg': {
              fill: '#0F266D'
            },
            '> span': {
              color: '#0F266D'
            }
          }}
        >
          <Text
            as="span"
            color="#1B51DC"
            fontSize="16px"
            fontWeight="500"
            letterSpacing="-1px"
            lineHeight="25px"
            textDecoration="underline"
            textUnderlineOffset="1px"
          >
            https://mbpbackoffice.pluxee.com.br/
          </Text>

          <Box
            as="svg"
            width="1em"
            height="1em"
            viewBox="0 0 18 18"
            fill="#1B51DC"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.75 0H18V14.7857H3.75V0ZM5.75 2H16V12.7857H5.75V2Z"
            />
            <path d="M0 18.0001V2.57153H2V16.0001H15V18.0001H0Z" />
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default NewEnvironment
